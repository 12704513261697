import React from 'react'
import logo from './logo.svg';
import { Container, Header, Content, Footer, Panel, Checkbox, List, InputGroup, Input, FlexboxGrid, Form, Button, InputPicker } from 'rsuite';
import './App.css';
import 'rsuite/dist/rsuite.min.css';

const data = [
  {
		"label": "članstvo v društvu (29 €)",
		"value": 1
	},
	{
		"label": "ekskurzija po Sloveniji in zamejstvu (25/35 €)",
		"value": 2
	},
	{
		"label": "dobroimetje za društvene ekskurzije",
		"value": 3
	},
	{
		"label": "članstvo v društvu + ekskurzija po Sloveniji in zamejstvu (54 €)",
		"value": 4
	},
	{
		"label": "članstvo v društvu + pohodna ekskurzija (40 €)",
		"value": 5
	},
	{
		"label": "članstvo v društvu + dobroimetje za društvene ekskurzije",
		"value": 6
	},
]
const znesek = [
  {
		"label": "50 €",
		"value": 50
	},
	{
		"label": "100 €",
		"value": 100
	},
	{
		"label": "200 €",
		"value": 200
	},
]

function App() {
  const [value, setValue] = React.useState(null);
  const [valueDobroImetje, setValueDobroImetje] = React.useState(null);
  const [valueClan, setValueClan] = React.useState(false);

  

  return (
    <div className="">
      <Container>
        <Header className="Header">
          <img src={logo} className="App-logo" alt="logo" />
        </Header>
        <Content>
          <FlexboxGrid justify="center">
            <FlexboxGrid.Item colspan={12} className="text-description">
              <h1>Naročilo darilnega bona</h1>
              <p>Izbira ekskurzije z darilnim bonom je po lastni izbiri. Darilni bon lahko izkoristite do konca koledarskega leta, 31. 12. 2024.</p>
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <FlexboxGrid justify="center">
            <FlexboxGrid.Item colspan={12}>
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel>Ime in priimek plačnika</Form.ControlLabel>
                  <Form.Control name="name" />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>E-poštni naslov</Form.ControlLabel>
                  <Form.Control name="name" />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Telefonska številka</Form.ControlLabel>
                  <Form.Control name="name" />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Izberite vrsto darilnega bona</Form.ControlLabel>
                  <InputPicker data={data} value={value} onChange={setValue} style={{width: "100%"}} />
                </Form.Group>
                {
                  value === 2 && <Form.Group>
                  <Checkbox value={true} onChange={setValueClan}> Kupec darilnega bona je član LGD*</Checkbox>
                  <Form.HelpText tooltip>Lorem ipsum</Form.HelpText>
                </Form.Group>
                }
                {
                  value === 6 && <Form.Group>
                  <Form.ControlLabel>Vpišite poljuben znesek</Form.ControlLabel>
                  <InputGroup style={{width: "50%"}}>
                    <Input value={valueDobroImetje} onChange={setValueDobroImetje} /><InputGroup.Addon>€</InputGroup.Addon>
                </InputGroup>
                </Form.Group>
                }
                {
                  value === 3 && <Form.Group>
                  <Form.ControlLabel>Izberite vrednost bona</Form.ControlLabel>
                  <InputPicker data={znesek} value={valueDobroImetje} onChange={setValueDobroImetje} style={{width: "100%"}} />
                </Form.Group>
                }
                <Form.Group>
                  <Button appearance="ghost" color="blue">Nakup darilnega bona</Button>
                </Form.Group>
              </Form>
            </FlexboxGrid.Item>
          </FlexboxGrid>
          {value !== null && <FlexboxGrid justify="center">
            <FlexboxGrid.Item colspan={12}>
              <Panel header="Skupaj za plačilo">
                <List>
                  <List.Item>
                    <FlexboxGrid>
                      <FlexboxGrid.Item colspan={12}>
                        { value !== null && ((data.filter(obj => {return(obj.value === value)}))[0].label)}
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item colspan={6} style={{textAlign: "right"}}>
                        {value === 1 && "29 €"}
                        {value === 2 && (valueClan ? "25 €" : "35 €")}
                        {value === 3 && (valueDobroImetje + " €")}
                        {value === 4 && "54 €"}
                        {value === 5 && "40 €"}
                        {value === 6 && (valueDobroImetje + " €")}
                      </FlexboxGrid.Item>
                    </FlexboxGrid>
                  </List.Item>
                </List>
              </Panel>
            </FlexboxGrid.Item>
          </FlexboxGrid>}
        </Content>
        <Footer>
          <FlexboxGrid justify="center">
            <FlexboxGrid.Item colspan={12}>
              <p>Ljubljansko geografsko društvo (LGD) je stanovska organizacija, ki združuje geografe in geografinje, študente in študentke geografije, kakor tudi vse ljubitelje in ljubiteljice geografske stroke, zlasti iz osrednjega dela Slovenije.</p>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Footer>
      </Container>
    </div>
  );
}

export default App;
